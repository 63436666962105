<template>
  <v-app id="inspire">
    <v-app-bar class="px-3" color="primary" flat density="compact">
      <v-avatar color="grey-darken-1" size="32" image="logo.png"></v-avatar>

      <v-spacer></v-spacer>

      <v-tabs centered v-model="selectedTab" align-tabs="title">
        <v-tab v-for="tab in tabs" :key="tab" :value="tab">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <v-spacer></v-spacer>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <span class="text-h5 mdi mdi-dots-vertical"></span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="setDeconnexion">Déconexion</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-window v-model="selectedTab">
      <v-window-item v-for="tab in tabs" :key="tab" :value="tab">
        <v-main class="bg-grey-lighten-3">
          <component :is="tab.component"></component>
        </v-main>
      </v-window-item>
    </v-window>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";

import GenerationPlage from "@/components/GenerationPlage.vue";
import GenerationKey from "@/components/GenerationKey.vue";
import GenerationImport from "@/components/GenerationImport.vue";
import GenerationFolder from "@/components/GenerationFolder.vue";
import GenerationMine from "@/components/GenerationMine.vue";

export default defineComponent({
  name: "MainView",
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          label: "GENERATION PAR PLAGE",
          component: "GenerationPlage",
        },
        {
          label: "GENERATION PAR KEY",
          component: "GenerationKey",
        },
        {
          label: "GENERATION PAR IMPORT",
          component: "GenerationImport",
        },
        {
          label: "GENERATION PAR DOSSIER",
          component: "GenerationFolder"
        },
        {
          label: "MES PRODUCTEURS",
          component: "GenerationMine"
        },
      ],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  async mounted() {
    if (!parseInt(sessionStorage.getItem("gcarte_key"))) {
      await this.setDeconnexion()
    }
  },
  components: {
    GenerationPlage,
    GenerationKey,
    GenerationImport,
    GenerationFolder,
    GenerationMine,
  },
  methods: {
    async setDeconnexion () {
      this.$router.replace('/')
    }
  }
});
</script>
