<template>
  <v-overlay
    scroll-strategy="block"
    v-model="is_show"
    class="d-flex align-center justify-center"
    hide-overlay
  >
    <v-sheet
      elevation="12"
      min-width="400"
      rounded="lg"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-check-circle"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ header }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">{{ message }}</p>

      <v-divider class="mb-4"></v-divider>

      <div class="text-end">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
          @click="this.is_show = false"
          >Done</v-btn
        >
      </div>
    </v-sheet>
  </v-overlay>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "NotificationComponent",
  props: ["header", "message", "show"],
  data () {
    return {
        is_show: this.show
    }
  }
});
</script>
