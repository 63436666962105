<template>
  <v-container>
    <Notification
      header="Success"
      message="Exportation effectuée"
      :show="this.modal_state"
    />
    <v-row>
      <v-col cols="12" sm="2">
        <v-card rounded="lg" min-height="268" class="pa-3">
          <v-form v-model="form" @submit.prevent="onSubmit">
            <v-textarea label="Plage en Key" v-model="plage" :rules="[required]" clearable></v-textarea>

            <br />

            <v-btn
              :disabled="!form"
              :loading="loading"
              block
              color="success"
              size="large"
              type="submit"
              variant="elevated"
              >Charger</v-btn
            >
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8">
        <v-card class="pa-3">
          <div
            class="card shadow mb-3 d-flex align-items-center justify-content-center"
          >
            <div
              style="width: 1149.75px !important; height: 715.125px !important"
              class="bg-danger printMe"
              ref="printMe"
              id="element-to-capture-key"
            >
              <b class="fokontany">{{ this.localisation.fokontany }}</b>
              <b class="commune">{{ this.localisation.commune }}</b>
              <b class="district">{{ this.localisation.district }}</b>
              <b class="region">{{ this.localisation.region }}</b>
              <b class="tel">{{ this.information.tel }}</b>
              <b class="cin_lieu">{{ this.information.cin_lieu }}</b>
              <b class="cin_date">{{ this.information.cin_date }}</b>
              <b class="cin">{{ this.information.cin }}</b>
              <b class="naissance_lieu">{{
                this.information.naissance_lieu
              }}</b>
              <b class="naissance_date">{{
                this.information.naissance_date
              }}</b>
              <b class="anarana">{{ this.information.anarana }}</b>
              <span class="created_at">{{ this.information.created_at }}</span>
              <b class="matricule">{{ this.information.matricule }}</b>
              <img :src="this.information.qr" class="qr-card" alt="" />
              <img :src="this.information.avatar" class="avatar-card" alt="" />
              <img src="template-v3-face.jpg" class="bg-card" alt="" />
            </div>
          </div>
        </v-card>
        <v-card class="mx-auto mt-4 overflow-auto" height="500">
            <v-list density="compact">
              <v-list-subheader>PRODUCTEURS</v-list-subheader>
              <v-list-item v-for="(prod, i) in this.producteurs" :key="i" :value="prod">
                  <v-list-item-title v-text="prod.matricule"></v-list-item-title>
                  <template v-slot:append>
                      <span v-if="prod.statut" class=" text-h5 mdi mdi-check"></span>
                      <span v-else class=" text-h5 mdi mdi-account-reactivate-outline"></span>
                      <v-btn @click="setView(prod.id)" icon="mdi-eye" variant="text"></v-btn>
                      <v-btn @click="deleteProd(prod.id)" icon="mdi-delete" color="red" variant="text"></v-btn>
                  </template>
              </v-list-item>
            </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" sm="2">
        <v-card rounded="lg" min-height="268" class="pa-3">
          <div class="text-h1 d-flex justify-center">
            {{ producteurs.length - (exported ? exported + 1 : 0) }}
          </div>
          <v-btn
            :disabled="producteurs.length < 1"
            :loading="loadingTache"
            block
            color="success"
            size="large"
            type="submit"
            variant="elevated"
            class="mt-3"
            @click="startTache"
            >Exporter</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import config from "@/config.json";
import html2canvas from "html2canvas";
import Format from "@/composables/Format.js";
import Notification from "./Notification.vue";
export default defineComponent({
  name: "GenerationPlage",
  components: {
    Notification,
  },
  data() {
    return {
      modal_state: false,
      form: false,
      loading: false,
      loadingTache: false,
      plage: '',
      text: "ici les plages",
      loading: false,
      producteurs: [],
      information: {
        avatar: "avatar.jpg",
        qr: "qrcode.png",
        anarana: "ANDRIATOABOLA David Henintsoa",
        naissance_date: "13 04 1978",
        naissance_lieu: "SOAVINANDRIANA",
        cin: 113011021097,
        cin_date: "22 04 1997",
        cin_lieu: "SOAVINANDRIANA",
        matricule: "PRD26112112572799",
        tel: "+261 34 42 275 98",
        created_at: "15 01 2022",
      },
      localisation: {
        region: "BONGOLAVA",
        district: "TSIROANOMANDIDY",
        commune: "ANKADINONDRY SAKAY",
        fokontany: "ANTSAHATANTERAKA",
      },
      exported: null,
    };
  },
  methods: {
    async onSubmit() {
      if (!this.form) return;
      this.loading = true;

      await axios
        .post(`${config.hote}/g-carte/producteur/key`,{
          key: this.plage,
          token: sessionStorage.getItem("gcarte_token")
        }).then((response) => {
          this.loading = false;
          this.producteurs = [];
          if (response.data.length > 0) {
            response.data.forEach((prod) => {
              this.producteurs.push(prod);
            });
          }
        })
        .catch(() => {
          this.loading = false;
          alert("Connexion faild");
        });
    },
    required(v) {
      return !!v || "Field is required";
    },
    async captureScreenshot(file_name) {
      const element = document.getElementById("element-to-capture-key");
      // Capture du contenu de l'élément
      return await html2canvas(element).then(async (canvas) => {
        // Conversion du canvas en image
        const screenshot = canvas.toDataURL("image/png");
        // Création d'un lien de téléchargement pour l'image
        const link = document.createElement("a");
        link.download = `${file_name}.png`;
        link.href = screenshot;
        link.click();
        return true
      });
    },
    async setView(id, prod = null) {
        console.log('launch setView');
        const MyFormat = new Format();
        if (prod === null) {
            /*const selectedProd = this.producteurs.find(
                (prod) => prod.information.id == id
            );
            this.information = {
                avatar: selectedProd.information.avatar,
                qr: selectedProd.information.qr,
                anarana: selectedProd.information.anarana,
                naissance_date: MyFormat.date(
                    selectedProd.information.naissance_date
                ),
                naissance_lieu: selectedProd.information.naissance_lieu,
                cin: selectedProd.information.cin,
                cin_date: MyFormat.date(selectedProd.information.cin_date),
                cin_lieu: selectedProd.information.cin_lieu,
                matricule: selectedProd.information.matricule,
                tel: MyFormat.phone(selectedProd.adresse_contacte.tel),
                created_at: selectedProd.information.created_at
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join(" "),
            };
            this.localisation = {
                region: selectedProd.localisation.region.toUpperCase(),
                district: selectedProd.localisation.district,
                commune: selectedProd.localisation.commune,
                fokontany: selectedProd.localisation.fokontany,
            };*/
        } else {
            const selectedProd = prod;
            return await axios.post(`${config.hote}/g-carte/producteur/show`,{
                id: selectedProd.id,
                token: sessionStorage.getItem("gcarte_token")
            }).then(async (response) => {
                this.information = {
                    id: response.data.id,
                    avatar: response.data.avatar_content,
                    qr: response.data.qr,
                    anarana: response.data.anarana.toUpperCase(),
                    naissance_date: MyFormat.date(
                        response.data.naissance_date
                    ),
                    naissance_lieu: response.data.naissance_lieu.toUpperCase(),
                    cin: response.data.cin,
                    cin_date: MyFormat.date(response.data.cin_date),
                    cin_lieu: response.data.cin_lieu.toUpperCase(),
                    matricule: response.data.matricule,
                    tel: MyFormat.phone((response.data.adresse_contacte) ? response.data.adresse_contacte.tel : ''),
                    created_at: response.data.created_at
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(" "),
                };
                this.localisation = {
                    region: (response.data.localisation) ? response.data.localisation.region.toUpperCase() : '',
                    district: (response.data.localisation) ? response.data.localisation.district.toUpperCase() : '',
                    commune: (response.data.localisation) ? response.data.localisation.commune.toUpperCase() : '',
                    fokontany: (response.data.localisation) ? response.data.localisation.fokontany.toUpperCase() : '',
                };

                return await new Promise((r) => setTimeout(r, 1000)).then(() => {
                    return true;
                });
            }).catch(() => {
                return false;
            })
        }
    },
    async startTache() {
        //console.log(this.producteurs.length);
        this.loadingTache = true
        if (this.exported == null) {
            this.exported = 0;

            const pr = this.producteurs[this.exported];
            await this.setView(null, pr).then(async (s) => {
                if (s) {
                  await this.captureScreenshot(pr.matricule).then(
                    async () => {
                      await axios.post(`${config.hote}/g-carte/producteur/update`, {
                          id: pr.id,
                          token: sessionStorage.getItem("gcarte_token")
                      }).then(async() => {
                          this.producteurs[this.exported].statut = true;
                          await this.startTache();
                      }).catch(async(err) => {
                          alert(err)
                          throw err;
                      })
                      this.producteurs[this.exported].statut = true;
                      await this.startTache();
                    }
                  );
                } else {
                  await this.startTache();
                }
            });
        } else {
            if (this.exported + 1 <= this.producteurs.length - 1) {
                this.exported += 1;

                const pr = this.producteurs[this.exported];
                await this.setView(null, pr).then(async () => {
                  await this.captureScreenshot(pr.matricule).then(
                    async () => {
                      await axios.post(`${config.hote}/g-carte/producteur/update`, {
                          id: pr.id,
                          token: sessionStorage.getItem("gcarte_token")
                      }).then(async() => {
                          this.producteurs[this.exported].statut = true;
                          await this.startTache();
                      }).catch(async(err) => {
                      })
                      this.producteurs[this.exported].statut = true;
                      await this.startTache();
                    }
                  );
                });
            } else {
                await new Promise((r) => setTimeout(r, 2000)).then(() => {
                    alert("Finished")
                    this.modal_state = true
                    this.loadingTache = false
                    this.exported = null
                });
            }
        }
    }
  },
});
</script>