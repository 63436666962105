export default class Format {

    phone = (n) => {
        if (n !== null && n) {
            if (n.length == 10) {
                return "+261 " + n.substr(1, 2) + " " + n.substr(3, 2) + " " + n.substr(5, 3) + " " + n.substr(8)
            } else if (n.length == 9) {
                "+261 " + n.substr(0, 2) + " " + n.substr(2, 2) + " " + n.substr(4, 3) + " " + n.substr(7)
            } else {
                return n
            }
        } else {
            return ''
        }
    }

    date = (d) => {
        if (d !== null && d) {
            return d.split('-').reverse().join(' ')
        } else {
            return ''
        }
    }

}