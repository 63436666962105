<template>
  <v-card>
    <v-layout>
      <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

      <v-app-bar color="primary" prominent>
        <v-toolbar-title>Carte producteur generator</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon>
          <span class="text-h5 mdi mdi-dots-vertical"></span>
        </v-btn>
        <v-btn variant="text" icon @click="closeApp">
          <span class="text-h5 mdi mdi-close-circle-outline"></span>
        </v-btn>
      </v-app-bar>

      <v-main>
        <v-sheet style="height: 100em" class="pa-12 bg-grey-lighten-3" rounded>
          <v-card class="bg-white mx-auto px-6 py-8" max-width="344">
            <v-form v-model="form" @submit.prevent="onSubmit">
              <v-text-field
                v-model="email"
                :readonly="loading"
                :rules="[required]"
                class="mb-2"
                clearable
                label="Email"
              >
              <template v-slot:prepend>
                <span class=" text-h5 mdi mdi-mail"></span>
              </template>
            </v-text-field>

              <v-text-field
                v-model="password"
                :readonly="loading"
                :rules="[required]"
                clearable
                label="Password"
                type="password"
                placeholder="Enter your password"
              >
                <template v-slot:prepend>
                  <span class=" text-h5 mdi mdi-key"></span>
                </template>
              </v-text-field>

              <br />

              <v-btn
                :disabled="!form"
                :loading="loading"
                block
                color="success"
                size="large"
                type="submit"
                variant="elevated"
              >
                Se connecter
              </v-btn>
            </v-form>
          </v-card>
        </v-sheet>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import config from "@/config.json";
//import { ipcRenderer } from "electron";
export default defineComponent({
  name: "LoginView",
  data() {
    return {
      form: false,
      email: null,
      password: null,
      loading: false,
    };
  },
  mounted() {
    sessionStorage.setItem("gcarte_key",0)
    sessionStorage.setItem("gcarte_token","")
  },
  methods: {
    async onSubmit() {
      if (!this.form) return;

      this.loading = true;

      await axios
        .post(config.hote + "/check-user", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status) {
            this.statut = response.data;
            sessionStorage.setItem("gcarte_key",1)
            sessionStorage.setItem("gcarte_token",response.data.user.token)
            this.$router.push("/home");
          } else {
            alert("Identifiant incorrect !");
          }
        })
        .catch((err) => {
          this.loading = false;
          alert(err.message);
        });
    },
    required(v) {
      return !!v || "Field is required";
    },
    closeApp () {
      //ipcRenderer.send('close-app');
    }
  },
});
</script>
